import {Component, Input} from '@angular/core'
import {FormGroup} from '@angular/forms'
import {IKycForm} from '@sparbanken-syd/spb-savings-account-backend'
import {OrientationService} from '../../../services/orientation.service'

@Component({
  selector: 'spb-american-citizenship',
  templateUrl: './american-citizenship.component.html',
  styleUrl: '../kyc.component.scss'
})
export class AmericanCitizenshipComponent {
  @Input({required: true}) americanCitizenshipForm!: FormGroup
  @Input({required: true}) kycForm!: IKycForm

  constructor(protected orientationService: OrientationService) {
  }
}
