<form [formGroup]="kycForm" #kycFormElement>
  <div class="centered-container">
    <mat-progress-bar
      mode="determinate"
      [value]="currentStep * (1 / (totalSteps - 1)) * 100">
    </mat-progress-bar>

    <div class="step-counter">{{ currentStep + 1 }}/{{ totalSteps }}</div>
  </div>

  @if (currentStep === KycType.BIRTH) {
    <spb-birth
      [birthForm]=getFormGroup(kycForm.controls.birthForm)></spb-birth>
  }

  @if (currentStep === KycType.PRIMARY_RESIDENCE) {
    <spb-primary-residence
      [primaryResidenceForm]=getFormGroup(kycForm.controls.primaryResidenceForm)>

    </spb-primary-residence>
  }

  @if (currentStep === KycType.GREEN_CARD) {
    <spb-green-card
      [greenCardForm]=getFormGroup(kycForm.controls.greenCardForm)>
      >
    </spb-green-card>
  }

  @if (currentStep === KycType.AMERICAN_CITIZENSHIP) {
    <spb-american-citizenship
      [americanCitizenshipForm]=getFormGroup(kycForm.controls.americanCitizenshipForm)
      [kycForm]="kycForm.value"
    >
    </spb-american-citizenship>
  }

  @if (currentStep === KycType.TAX_RESIDENCY) {
    <spb-tax-residency
      [taxResidencyForm]=getFormGroup(kycForm.controls.taxResidencyForm)
      [kycForm]="kycForm.value"
    >
    </spb-tax-residency>
  }

  @if (currentStep === KycType.PEP) {
    <spb-pep
      [pepForm]=getFormGroup(kycForm.controls.pepForm)>
    </spb-pep>
  }

  @if (currentStep === KycType.BANK_RELATIONSHIP) {
    <spb-bank-relationship
      [latestInterest]="latestInterest"
      [bankRelationshipForm]=getFormGroup(kycForm.controls.bankRelationshipForm)>
    </spb-bank-relationship>
  }

  @if (currentStep === KycType.INCOME) {
    <spb-income
      [incomeForm]=getFormGroup(kycForm.controls.incomeForm)>
    </spb-income>
  }

  @if (currentStep === KycType.SAVINGS) {
    <spb-savings
      [savingsForm]=getFormGroup(kycForm.controls.savingsForm)
    ></spb-savings>
  }

  @if (showWarningText) {
    <mat-error><p>Ange konto som pengarna ska dras ifrån eller välj att öppna
      ett sparkonto.</p></mat-error>
  }

  <div class="navigation-buttons">
    @if (currentStep !== 0) {
      <button (click)="previousStep()"
              class="sparbanken-button white"
              [disabled]="currentStep === 0">
        Föregående
      </button>
    }

    @if (currentStep !== totalSteps - 1) {
      <button
        class="sparbanken-button"
        [disabled]="!savingsAccountService.testMode$() && disabledButton()"
        (click)="nextStep(kycForm.controls.bankRelationshipForm)"
      >Nästa
      </button>
    } @else {
      <spb-next-button [valid]="kycForm.valid"></spb-next-button>
    }
  </div>
</form>
