<h3>Person i politiskt utsatt ställning (PEP)</h3>

<p class="select-title">Har du ett yrke som gör att du är en person i politiskt
  utsatt ställning
  (PEP) eller är du anhörig alternativt nära medarbetare till någon i politiskt
  utsatt ställning (RCA)? <a
    href="https://www.swedishbankers.se/foer-bankkunder/penningtvaett/personer-i-politisk-utsatt-staellning-pep"
    target="_blank">Läs mer.</a></p>

<form [formGroup]="pepForm">
  <div class="margin-bottom-medium">

    <mat-radio-group
      color="primary"
      formControlName="pepStatus">
      @for (option of pepStatus; track $index) {
        <div class="margin-bottom-small"
             [class.small-screen]="orientationService.isSmallScreen$()"
        >
          <mat-radio-button
            [value]="option.value"
          >
            {{ option.label }}
          </mat-radio-button>
        </div>
      }
    </mat-radio-group>
  </div>


  @if (pepForm.get('pepStatus')!.value === 'pep' ||
  pepForm.get('pepStatus')!.value === 'rca') {
    <div class="margin-bottom-medium column">
      <p class="select-title">Vänligen specificera arbetstiteln för
        {{ pepForm.get('pepStatus')!.value === 'pep' ? 'dig' : 'personen' }} i
        politiskt utsatt ställning</p>
      @for (option of pepTitles; track $index) {
        <div class="margin-bottom-small"
             [class.small-screen]="orientationService.isSmallScreen$()"
        >
          <mat-checkbox
            color="primary"
            [checked]="option.value === pepForm.controls.pepTitle.value"
            (change)="onCheckboxChange(option.value, 'pepTitle', $event.checked)">
            {{ option.label }}
          </mat-checkbox>
        </div>

        @if (option.value === pepForm.controls.pepTitle.value) {
          <div>
            <mat-form-field>
              <mat-label>Ange befattning och organisation</mat-label>
              <input matInput formControlName="pepPosition"
                     placeholder="Fritext">
            </mat-form-field>
          </div>

          <div>
            <mat-form-field>
              <mat-label>Välj land</mat-label>
              <mat-select
                placeholder="Välj land"
                formControlName="pepCountry"
                ngDefaultControl>
                @for (country of countryList; track $index) {
                  <mat-option
                    [ngClass]="{'separator-after': $index === 0}"
                    [value]="country.name">{{ country.name }}
                  </mat-option>
                }
              </mat-select>
              <mat-error>Vänligen gör ett val</mat-error>
            </mat-form-field>
          </div>
        }
      }
    </div>
    @if (pepForm.get('pepStatus')!.value === 'rca') {
      <div class="margin-bottom-medium column">
        <p class="select-title">Vilken relation har du till personen i
          politiskt utsatt
          ställning?</p>
        @for (option of rcaRelations; track $index) {
          <div class="margin-bottom-small"
               [class.small-screen]="orientationService.isSmallScreen$()">
            <mat-checkbox
              color="primary"
              [checked]="option.value === pepForm.controls.pepRelation.value"
              (change)="onCheckboxChange(option.value, 'pepRelation', $event.checked)">
              {{ option.label }}
            </mat-checkbox>
          </div>
        }
      </div>
    }

    @if (pepForm.get('pepStatus')!.value === 'pep') {
      <mat-checkbox
        formControlName="pepOtherWork"
        color="primary">
        Jag har förutom ovan även fler arbete i politiskt utsatt ställning
      </mat-checkbox>
      @if (pepForm.controls.pepOtherWork.value) {
        <p class="select-title" [style.max-width.px]="450">
          {{ pepWorkText }}
        </p>
        <mat-form-field
          class="text-area"
          [class.small-screen]="orientationService.isSmallScreen$()"
        >
        <textarea matInput formControlName="pepOtherWorkInfo"
                  [placeholder]="pepWorkText"
                  placeholder=""
                  rows="4"
        ></textarea>
        </mat-form-field>
      }
    }

    <mat-checkbox
      formControlName="pepOtherRelation"
      color="primary">
      {{
            pepForm.get('pepStatus')!.value === 'pep' ?
                'Jag är dessutom anhörig alternativt nära medarbetare till någon som är, eller de senaste 18 månaderna varit, en person i politiskt utsatt ställning (RCA)' :
                'Jag har förutom ovan även relation till fler personer i politiskt utsatt ställning'
      }}
    </mat-checkbox>
    @if (pepForm.controls.pepOtherRelation.value) {
      <p class="select-title" [style.max-width.px]="450">
        {{
              pepForm.get('pepStatus')!.value === 'pep' ?
                  pepRelationText :
                  rcaRelationText
        }}
      </p>
      <mat-form-field
        class="text-area"
        [class.small-screen]="orientationService.isSmallScreen$()"
      >
        <textarea matInput formControlName="pepOtherRelationInfo"
                  [placeholder]="pepForm.get('pepStatus')!.value === 'pep' ?
                pepRelationText :
                rcaRelationText"
                  placeholder=""
                  rows="4"
        ></textarea>
      </mat-form-field>

    }
  }

</form>