"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SignatureData = exports.KycForm = exports.ApplicantData = exports.AccountApplication = exports.AccountRequest = void 0;
const removeUndefinedProperties = obj => {
  Object.keys(obj).forEach(key => {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  });
};
class AccountRequest {
  constructor(obj) {
    this.applicantData = new ApplicantData(obj.applicantData);
    this.kycForm = new KycForm(obj.kycForm);
  }
}
exports.AccountRequest = AccountRequest;
/********************************************************
 * Account Application - Once a request is sent to the API
 * to be saved, it is transformed into AccountApplication
 ********************************************************/
const ApplicationStatus = ['pending', 'handled'];
const RiskStatus = ['normal', 'high'];
class AccountApplication extends AccountRequest {
  constructor(obj) {
    super(obj);
    this.id = obj.id;
    this.created = obj.created;
    this.version = obj.version;
    this.gdprRemove = obj.gdprRemove;
    this.status = obj.status;
    this.risk = obj.risk;
    this.adminData = obj.adminData;
    if (obj.signature) {
      this.signature = new SignatureData(obj.signature);
    }
  }
  get isSigned() {
    return !!this.signature?.isComplete;
  }
}
exports.AccountApplication = AccountApplication;
class ApplicantData {
  constructor(obj) {
    this.name = obj.name;
    this.personalNumber = obj.personalNumber;
    this.countryCode = obj.countryCode;
    this.email = obj.email;
    this.phone = obj.phone;
    this.sparFailed = obj.sparFailed;
    // Optional data can be undefined. It needs to be removed from JSON if so
    this.postalCode = obj.postalCode;
    this.streetAdress = obj.streetAdress;
    this.streetNumber = obj.streetNumber;
    this.city = obj.city;
    removeUndefinedProperties(this);
  }
}
exports.ApplicantData = ApplicantData;
class KycForm {
  constructor(obj) {
    this.birthForm = obj.birthForm;
    this.primaryResidenceForm = obj.primaryResidenceForm;
    this.greenCardForm = obj.greenCardForm;
    this.americanCitizenshipForm = obj.americanCitizenshipForm;
    this.taxResidencyForm = obj.taxResidencyForm;
    this.pepForm = obj.pepForm;
    this.bankRelationshipForm = obj.bankRelationshipForm;
    this.incomeForm = obj.incomeForm;
    this.savingsForm = obj.savingsForm;
  }
  static default() {
    return new KycForm({
      birthForm: {
        birthCountry: '',
        birthCity: '',
        citizenship: []
      },
      primaryResidenceForm: {
        primaryResidence: ''
      },
      greenCardForm: {
        greenCard: false
      },
      americanCitizenshipForm: {
        americanCitizenship: false
      },
      taxResidencyForm: {
        taxResidencySwedish: false,
        taxResidencyNotSwedish: false,
        taxResidencyInfo: [],
        notUsaCitizenCheckbox: false,
        onlySwedenTaxCheckbox: false
      },
      pepForm: {
        pepStatus: 'notPep',
        pepTitle: '',
        pepPosition: '',
        pepCountry: '',
        pepRelation: ''
      },
      bankRelationshipForm: {
        openSavingsAccount: false,
        interestRunningTime: '3',
        interestPlacementAmount: 0,
        wantToBeContacted: false,
        existingSavingsAccount: false,
        existingSavingsAccountNumber: null
      },
      incomeForm: {
        salary: 0,
        pension: 0,
        benefits: 0,
        studentAid: 0,
        soleProprietorship: 0,
        capital: 0,
        other: 0
      },
      savingsForm: {
        savingsTotal: 0,
        savingsSource: []
      }
    });
  }
}
exports.KycForm = KycForm;
class SignatureData {
  constructor(obj) {
    this.reference = obj.reference;
    this.personalNumber = obj.personalNumber;
    removeUndefinedProperties(this);
  }
  get isComplete() {
    return !!this.personalNumber && !!this.reference;
  }
}
exports.SignatureData = SignatureData;
