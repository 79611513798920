import {Injectable} from '@angular/core'
import {catchError, map} from 'rxjs/operators'
import {EMPTY, Observable} from 'rxjs'
import {environment} from '../../environments/environment'
import {HttpClient} from '@angular/common/http'
import {MatSnackBar} from '@angular/material/snack-bar'

export interface Interest {
  [key: number]: number
  createdByName: string
  version: number
  approvedByName: string
  itemId: string
  interestStartDate: number
  createdBySub: string
  approvedBySub: string
  eSpar: number
  timeStamp: number
  interfaceDate: number
  typeOfInterest: string
}


@Injectable({
  providedIn: 'root'
})
export class InterestService {

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly httpClient: HttpClient
  ) {
  }

  /**
   * Get the latest valid interest from database
   */
  public getLatestInterest(): Observable<Interest> {
    const url = `${environment.rtUrl}/interests/latest/fkInterests`
    return this.httpClient.get<Interest>(url)
      .pipe(
        catchError(() => {
          this.snackBar.open('Ett fel har inträffat. Räntan som anges är inte korrekt. Kontrollera din internetanslutning', 'OKEJ', {
            horizontalPosition: 'center',
            verticalPosition: 'top'
          })
          return EMPTY
        }),
        map((interest: Interest) => interest)
      )
  }
}
