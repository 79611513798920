<form [formGroup]="americanCitizenshipForm">
  <h3>Amerikanskt medborgarskap och skattskyldighet i USA</h3>
  <p>Den som är skattskyldig i USA (US Person) är bland annat:</p>

  <ul>
    <li>Person som är bosatt i USA</li>
    <li>Amerikansk medborgare (även person med dubbelt medborgarskap)</li>
    <li>Innehavare av amerikanskt uppehålls- och arbetstillstånd (s.k. Green
      card)
    </li>
    <li>Företag som är registrerat i USA</li>
  </ul>

  <p class="select-title">Vänligen bekräfta din status gällande amerikanskt
    medborgarskap.</p>
  <mat-radio-group
    color="primary"
    class="column"
    formControlName="americanCitizenship">
    <mat-radio-button [value]="false"
   class="margin-bottom-small"
         [class.small-screen]="orientationService.isSmallScreen$()"

    >Jag är inte amerikansk medborgare
      och/eller har ingen skatterättslig hemvist i USA
    </mat-radio-button>

    @if (this.americanCitizenshipForm.controls.americanCitizenship.value === false &&
    (this.kycForm.primaryResidenceForm.primaryResidence === 'USA' ||
        this.kycForm.birthForm.birthCountry === 'USA' ||
        this.kycForm.birthForm.citizenship.includes('USA') ||
        this.kycForm.greenCardForm.greenCard)
      ) {
      <div class="error-container">
        <mat-error>Ditt svar stämmer inte med dina
          tidigare svar, vänligen gå tillbaka och kontrollera dina svar.
        </mat-error>
      </div>
    }

    <mat-radio-button [value]="true">Jag är amerikansk medborgare och/eller har
      skatterättslig hemvist i USA
    </mat-radio-button>
  </mat-radio-group>
  <p>
</form>
