import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Injectable, signal, WritableSignal} from '@angular/core'
import {
  AccountApplication,
  AccountRequest, ApplicantData, Track
} from '@sparbanken-syd/spb-savings-account-backend'
import {SelectableItem} from '@sparbanken-syd/spb-savings-account-backend/dist'
import {Observable} from 'rxjs'
import {environment} from '../../environments/environment'
import {ISparRequest, ISparResponse} from 'sparbanken-spar'

@Injectable({
  providedIn: 'root'
})
export class SavingsAccountService {
  public testMode$: WritableSignal<boolean> = signal(false)
  public accessToken$: WritableSignal<string | undefined> = signal(undefined)
  public sessionTimeout$: WritableSignal<boolean> = signal(false)

  public applicant$: WritableSignal<ApplicantData> = signal({
    name: '', personalNumber: '', countryCode: '', email: '', phone: '',
    city: '', postalCode: '', streetNumber: '', streetAdress: ''
  })

  public incomeOptions: SelectableItem[] = [
    {value: 'salary', label: 'Lön', checked: false},
    {value: 'pension', label: 'Pension', checked: false},
    {
      value: 'benefits',
      label: 'Bidrag eller arbetslöshetsersättning',
      checked: false
    },
    {
      value: 'studentAid',
      label: 'Studiebidrag och/eller studielån',
      checked: false
    },
    {
      value: 'soleProprietorship',
      label: 'Inkomst från enskild firma',
      checked: false
    },
    {value: 'capital', label: 'Kapital', checked: false},
    {value: 'other', label: 'Annan', checked: false}
  ]

  public savingsSource: SelectableItem[] = [
    {value: 'Inkomst med löpande sparande', checked: false},
    {value: 'Arv eller gåva', checked: false},
    {value: 'Försäljning av bostad', checked: false},
    {
      value: 'Annan försäljning (företag eller andra tillgångar)',
      checked: false
    },
    {value: 'Spel eller lotterivinst', checked: false}
  ]

  constructor(private httpClient: HttpClient) {
  }

  /**
   * Updates applicant data based on response
   * @param userId - User ID
   * @param iSparResponse
   */
  public updateApplicantData(userId: string, iSparResponse?: ISparResponse): void {
    const entrance = iSparResponse?.addresses[0].entrance
    const apartment = iSparResponse?.addresses[0].apartment
    const generateApplicantData = (iSparResponse?: ISparResponse) => ({
      countryCode: '+46',
      email: '',
      phone: '',
      name: iSparResponse ? iSparResponse.name : '',
      personalNumber: userId,
      streetAdress: iSparResponse ? iSparResponse.addresses[0].streetName : '',
      streetNumber: iSparResponse ? `${iSparResponse.addresses[0].streetNumber}${entrance ? entrance : ''}${apartment ? ' ' + apartment : ''}` : '',
      postalCode: iSparResponse ? iSparResponse.addresses[0].postalCode : '',
      city: iSparResponse ? iSparResponse.addresses[0].city : '',
      sparFailed: iSparResponse ? false : true
    })

    const applicantData = generateApplicantData(iSparResponse)
    this.applicant$.set(applicantData)
  }

  public saveRequest(request: AccountRequest): Observable<AccountApplication> {
    const url = `${environment.requestApi}/request`
    let headers = new HttpHeaders()
    headers = headers.set('Authorization', `Bearer ${this.accessToken$()}`)
    return this.httpClient.put<AccountApplication>(url, request, {headers})
  }

  public saveTrack(): Observable<Track> {
    const url = `${environment.requestApi}/track`
    let headers = new HttpHeaders()
    headers = headers.set('Authorization', `Bearer ${this.accessToken$()}`)
    return this.httpClient.put<any>(url, {}, {headers})
  }

  /**
   * Add signature, the only thing we need is the access token.
   * @param accessToken - The token received after sign
   * @param id
   */
  public addSignature(accessToken: string, id: string | null): Observable<AccountApplication> {
    const url = `${environment.requestApi}/user/${id}/sign`
    let headers = new HttpHeaders()
    headers = headers.set('Authorization', `Bearer ${accessToken}`)
    return this.httpClient.put<AccountApplication>(url, {}, {headers})
  }

  public getSpar(sub: string): Observable<ISparResponse> {
    const url = `${environment.requestApi}/spar`
    const input: ISparRequest = {
      source: 'sparbanken-spar-fe',
      // This will be taken from the access token in real life
      sub
    }
    let headers = new HttpHeaders()
    headers = headers.set('Authorization', `Bearer ${this.accessToken$()}`)
    return this.httpClient.put<ISparResponse>(url, input, {headers})
  }
}
