import {Component, Input, OnInit} from '@angular/core'
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms'
import {IKycForm} from '@sparbanken-syd/spb-savings-account-backend'
import {OrientationService} from '../../../services/orientation.service'
import {FormUtils} from '../../../utils/form.utils'
import {countryList} from '@sparbanken-syd/spb-savings-account-backend/dist'

@Component({
  selector: 'spb-tax-residency',
  templateUrl: './tax-residency.component.html',
  styleUrl: '../kyc.component.scss'
})
export class TaxResidencyComponent implements OnInit {
  @Input({required: true}) taxResidencyForm!: any
  @Input({required: true}) kycForm!: IKycForm

  public workingAbroadOptions = [
    {value: 'workingAbroad', label: 'Arbetar utomlands'},
    {value: 'propertyAbroad', label: 'Fastighet eller lägenhet i utlandet'},
    {value: 'other', label: 'Annan'},
    {value: 'none', label: 'Ingen'}
  ]

  protected readonly countryList = countryList
    .filter(country => country.name !== 'Sverige')

  constructor(protected orientationService: OrientationService) {
  }

  get formGroups(): FormGroup[] {
    return (this.taxResidencyForm
      .get('taxResidencyInfo') as FormArray).controls as FormGroup[]
  }

  public ngOnInit() {
    this.taxResidencyForm.controls.taxResidencyNotSwedish.valueChanges
      .subscribe({
        next: (notSwedishTax: boolean) => {
          this.taxResidencyForm.controls.taxResidencyInfo.clear()
          if (notSwedishTax && this.checkIfUsaPerson()) {
            this.addControlsToFormGroup('USA')
          } else if (notSwedishTax) {
            this.addControlsToFormGroup()
          }
        }
      })
  }

  public onCheckboxChange(value: string, control: string, checked: boolean, formGroup: FormGroup) {
    // Update the form group's value based on the checkbox state
    formGroup.patchValue({[control]: checked ? value : null})

    // Determine if the 'other' option is selected and apply or remove validation accordingly
    const isOtherSelected = value === 'other'
    FormUtils.addOrRemoveValidation(isOtherSelected && checked, formGroup.controls.taxResidencyOtherRelation, [Validators.required])
  }

  public createControls(taxCountry?: string): FormGroup {
    return new FormGroup({
      taxResidencyCountry: new FormControl(taxCountry, {
        validators: [Validators.required]
      }),
      taxResidencyTin: new FormControl(null, {
        validators: []
      }),
      taxResidencyRelation: new FormControl(null, {
        validators: [Validators.required]
      }),
      taxResidencyOtherRelation: new FormControl(null, {
        validators: []
      })
    })
  }

  public addControlsToFormGroup(taxCountry?: string) {
    this.taxResidencyForm.get('taxResidencyInfo')
      .push(this.createControls(taxCountry))
  }

  public removeControl(index: number) {
    const array = this.taxResidencyForm
      .get('taxResidencyInfo') as FormArray
    array.removeAt(index)
  }

  public checkIfSwedenOnly(): boolean {
    const swedenOnly =
      this.taxResidencyForm.controls.taxResidencyNotSwedish.value === false &&
      this.kycForm.primaryResidenceForm.primaryResidence !== 'Sverige'

    if (swedenOnly) {
      FormUtils.addOrRemoveValidation(true,
        this.taxResidencyForm.controls.onlySwedenTaxCheckbox,
        [Validators.requiredTrue])
    } else {
      FormUtils.addOrRemoveValidation(false,
        this.taxResidencyForm.controls.onlySwedenTaxCheckbox,
        [Validators.requiredTrue])
    }
    return swedenOnly
  }

  public checkIfUsaPerson(): boolean {
    const usaPerson =
      (this.kycForm.primaryResidenceForm.primaryResidence === 'USA' ||
        this.kycForm.birthForm.birthCountry === 'USA' ||
        this.kycForm.birthForm.citizenship.includes('USA') ||
        this.kycForm.greenCardForm.greenCard)

      this.addUsaValidation(usaPerson)
    return usaPerson
  }

  private addUsaValidation(usaPerson: boolean) {
    if (usaPerson && this.taxResidencyForm.controls.taxResidencyNotSwedish.value === false) {
      FormUtils.addOrRemoveValidation(true,
        this.taxResidencyForm.controls.notUsaCitizenCheckbox,
        [Validators.requiredTrue])
    } else {
      FormUtils.addOrRemoveValidation(false,
        this.taxResidencyForm.controls.notUsaCitizenCheckbox,
        [Validators.requiredTrue])
    }
  }
}

