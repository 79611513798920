import {Component, Input, OnInit} from '@angular/core'
import {AbstractControl, FormGroup, Validators} from '@angular/forms'
import {Interest} from '../../../services/interest.service'
import {OrientationService} from '../../../services/orientation.service'
import {booleanOptions} from '../../../types'
import {FormUtils} from '../../../utils/form.utils'

@Component({
  selector: 'spb-bank-relationship',
  templateUrl: './bank-relationship.component.html',
  styleUrls: ['./bank-relationship.component.scss', '../kyc.component.scss']
})
export class BankRelationshipComponent implements OnInit {
  @Input() bankRelationshipForm!: FormGroup
  @Input() latestInterest!: Interest

  public interestRunningTimeOptions: {
    value: string,
    label: string,
    interestRate: string
  }[] = []

  public interestDate = ''
  public savingsInterest = ''
  public minInterestRate = ''
  public maxInterestRate = ''
  public showDuplicateError = false

  protected readonly yesOrNo = booleanOptions
  protected iHaveSpbAccount = 'Jag har ett konto på Sparbanken Syd som pengarna ska dras från'
  protected iWantNewAccount = 'Jag vill öppna ett sparkonto som pengarna ska dras från'

  constructor(
    protected orientationService: OrientationService
  ) {
  }

  public toggleAccount(accountType: string) {
    const control = this.bankRelationshipForm.get(accountType)
    if (control) {
      control.setValue(!control.value)
    }
  }

  public onCheckboxChange(value: string | boolean, control: string, checked: boolean) {
    if (checked) {
      this.bankRelationshipForm.patchValue({[control]: value})
    } else {
      this.bankRelationshipForm.patchValue({[control]: null})
    }
  }

  public ngOnInit() {
    this.initializeInterestOptions()
    this.setupFormValidation()
    this.calculateInterestsToHTML()
  }

  private initializeInterestOptions() {
    // Always start with an empty array of interest options
    this.interestRunningTimeOptions = []

    // Iterate over latestInterest to create interestRunningTimeOptions
    for (const [key, value] of Object.entries(this.latestInterest)) {
      // Check if the key is a numeric string, indicating a duration value
      if (!isNaN(Number(key))) {
        // Format the interest rate to two decimal places and replace the decimal point with a comma
        const formattedInterestRate = value.toFixed(2).replace('.', ',')

        // Add the formatted entry to interestRunningTimeOptions
        this.interestRunningTimeOptions.push({
          value: key, // '3', '6', '12' etc
          label: this.getLabelForInterestRunningTime(Number(key)), // Generate label based on key
          interestRate: formattedInterestRate // '3,60'
        })
      }
    }
    // savingsInterest should be x,xx%
    this.savingsInterest = this.latestInterest.eSpar.toFixed(2).replace('.', ',')

    //Show date as 2024-10-09 in template
    this.interestDate = new Date(this.latestInterest.interfaceDate).toLocaleDateString('sv-SE')
  }

  private getLabelForInterestRunningTime(months: number): string {
    if (months < 12) {
      return `${months} mån` // display as 'x months'
    } else {
      return `${(months / 12).toString().replace('.', ',')} år` // display as 'x years'
    }
  }

  private setupFormValidation() {
    this.setupFixedRateAccountValidation()
    this.setupExistingSavingsAccountValidation()
    this.setupOpenSavingsAccountValidation()
  }

  private setupFixedRateAccountValidation() {
    this.bankRelationshipForm.controls.openFixedRateAccount.valueChanges.subscribe(value => {
      const validators = [Validators.required]
      const amountValidators = [...validators, Validators.min(50000), Validators.max(10 * 1000000)]

      FormUtils.addOrRemoveValidation(value, this.bankRelationshipForm.controls.interestRunningTime, validators)
      FormUtils.addOrRemoveValidation(value, this.bankRelationshipForm.controls.interestPlacementAmount, amountValidators)

      if (!value) {
        this.bankRelationshipForm.controls.existingSavingsAccount.setValue(false)
        this.bankRelationshipForm.controls.existingSavingsAccountNumber.setValue(null)
      }

    })
  }

  private setupExistingSavingsAccountValidation() {
    this.bankRelationshipForm.controls.existingSavingsAccount.valueChanges.subscribe(value => {
      if (value) {
        this.handleDuplicateSelection(this.bankRelationshipForm.controls.openSavingsAccount)
        FormUtils.addOrRemoveValidation(true, this.bankRelationshipForm.controls.existingSavingsAccountNumber, [Validators.required, Validators.pattern(/^\d{10,}$/)])
        FormUtils.addOrRemoveValidation(false, this.bankRelationshipForm.controls.openSavingsAccount, [Validators.requiredTrue])
      } else {
        this.showDuplicateError = false
        FormUtils.addOrRemoveValidation(false, this.bankRelationshipForm.controls.existingSavingsAccountNumber, [Validators.required])
        FormUtils.addOrRemoveValidation(true, this.bankRelationshipForm.controls.openSavingsAccount, [Validators.requiredTrue])
      }
    })
  }

  private setupOpenSavingsAccountValidation() {
    this.bankRelationshipForm.controls.openSavingsAccount.valueChanges.subscribe(value => {
      if (value) {
        this.handleDuplicateSelection(this.bankRelationshipForm.controls.existingSavingsAccount)
      }
    })
  }

  private handleDuplicateSelection(otherControl: AbstractControl) {
    if (otherControl.value) {
      this.bankRelationshipForm.controls['existingSavingsAccount'].setValue(false)
      this.bankRelationshipForm.controls['openSavingsAccount'].setValue(false)
      this.showDuplicateError = true
    } else {
      this.showDuplicateError = false
    }
  }

  private calculateInterestsToHTML() {
    const interestRates = this.interestRunningTimeOptions.map(option => parseFloat(option.interestRate.replace(',', '.')))

    this.minInterestRate = Math.min(...interestRates).toFixed(2).replace('.', ',')
    this.maxInterestRate = Math.max(...interestRates).toFixed(2).replace('.', ',')
  }

}
