import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout'
import {Injectable, signal, WritableSignal} from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class OrientationService {
  /**
   * Property to track if the screen is large
   */
  public isSmallScreen$: WritableSignal<boolean> = signal(false)

  /**
   * Used to dynamically respond to changes in screen size using the
   * BreakpointObserver instance. Maybe also should use Breakpoints.TabletLandscape
   * @param breakpointObserver
   */
  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait,
      Breakpoints.TabletPortrait,
    ]).subscribe(r => this.isSmallScreen$.set(r.matches))
  }
}
