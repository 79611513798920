<div class="summary-holder">
  @if (signed) {
    <h3>Signering pågår...</h3>
  } @else {
    <h3>Godkännande av information</h3>
    <p>Du har nu fyllt i alla frågor</p>
    <spb-bankid
      skipDialogs
      mode="sign"
      [domain]="domain"
      customButton="Godkänn med BankID"
      [startUrl]="requestApi"
      [bankIdUrl]="authServiceUrl"
      [userRef]="accountApplication.id"
      [disabled]="!accountApplication.id"
      (accessToken)="submitSignature($event)"
      (click)="this.errorMessage = ''"
    >
    </spb-bankid>
    @if (errorMessage) {
      <div [style.text-align]="'center'">
        <mat-error>{{ errorMessage }}</mat-error>
      </div>
    }
  }
</div>
