import {AbstractControl, ValidatorFn} from '@angular/forms'

export class FormUtils {
  public static addOrRemoveValidation(add: boolean, control: AbstractControl, validators: ValidatorFn[]): void {
    if (add) {
      control.addValidators(validators)
    } else {
      control.setValidators(null)
      control.reset()
    }
    control.updateValueAndValidity({emitEvent: false})
  }

}