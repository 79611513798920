import {Component, OnInit} from '@angular/core'
import {verify} from '@sparbanken-syd/personnummer'
import {SparbankenUser} from '@sparbanken-syd/sparbanken-syd-bankid'
import {environment} from '../../environments/environment'
import {SavingsAccountService} from '../services/savings-account.service'

@Component({
  selector: 'spb-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  protected readonly authServiceUrl = environment.authServiceUrl
  protected readonly domain = environment.domain
  protected notAdult = false

  constructor(protected savingsAccountService: SavingsAccountService) {
  }

  public ngOnInit() {
    this.savingsAccountService.applicant$.set({
      countryCode: '+46', email: '', phone: '',
      name: '',
      personalNumber: ''
    })
  }

  /**
   * Sets user token and performs actions based on user's age
   * @param token - User token
   */
  public setToken(token: string) {
    this.savingsAccountService.accessToken$.set(token)
    const decodedPayload = this.decodeToken(token)
    const user = JSON.parse(decodedPayload)

    if (this.isUserUnderAge(user)) {
      this.handleUnderAgeUser()
    } else {
      this.handleAdultUser(user)
    }
  }

  /**
   * Decodes token and returns payload
   * @param token - User token
   * @returns Decoded payload
   */
  private decodeToken(token: string): string {
    const binaryString = atob(token.split('.')[1])
    const bytes = new Uint8Array(binaryString.length)

    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }

    return new TextDecoder('utf-8').decode(bytes)
  }

  /**
   * Checks if user is under 18 years old
   * @param user - User object
   * @returns Whether the user is under 18
   */
  private isUserUnderAge(user: any): boolean {
    return verify(user.sub).age < 18
  }

  /**
   * Handles actions for under age user
   */
  private handleUnderAgeUser(): void {
    this.notAdult = true
  }

  /**
   * Handles actions for adult user
   * @param token - User token
   * @param user - User object
   */
  private handleAdultUser(user: SparbankenUser): void {
    this.savingsAccountService.updateApplicantData(user.sub, undefined)
  }
}
