{
  "name": "savings-account-fe",
  "version": "1.3.0",
  "scripts": {
    "watch": "ng test",
    "build": "ng build",
    "install": "cp ./package.json ./src/assets",
    "lint": "ng lint",
    "ng": "ng",
    "start": "cp ./package.json ./src/assets && ng serve --ssl --host localhost.sparbanken.me",
    "style-lint": "stylelint  src/**/*.scss --fix",
    "test": "ng test --watch=false --code-coverage"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.4",
    "@angular/common": "^18.2.4",
    "@angular/compiler": "^18.2.4",
    "@angular/core": "^18.2.4",
    "@angular/forms": "^18.2.4",
    "@angular/platform-browser": "^18.2.4",
    "@angular/platform-browser-dynamic": "^18.2.4",
    "@angular/router": "^18.2.4",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.4",
    "@angular-eslint/builder": "18.3.1",
    "@angular-eslint/eslint-plugin": "18.3.1",
    "@angular-eslint/eslint-plugin-template": "18.3.1",
    "@angular-eslint/schematics": "18.3.1",
    "@angular-eslint/template-parser": "18.3.1",
    "@angular/cdk": "^18.2.4",
    "@angular/cli": "~18.2.4",
    "@angular/compiler-cli": "^18.2.4",
    "@angular/material": "^18.2.4",
    "@sparbanken-syd/personnummer": "^3.0.0",
    "@sparbanken-syd/sparbanken-syd-bankid": "^18.1.1",
    "@sparbanken-syd/sparbanken-syd-qr": "^18.1.0",
    "@sparbanken-syd/sparbanken-syd-theme": "^18.0.0",
    "@sparbanken-syd/spb-savings-account-backend": "^1.1.0",
    "@types/jasmine": "~5.1.4",
    "@types/sparbanken-spar": "^1.0.2",
    "@types/sparbanken-syd-auth-backend": "^2.0.0",
    "@typescript-eslint/eslint-plugin": "7.5.0",
    "@typescript-eslint/parser": "7.5.0",
    "eslint": "^8.57.0",
    "jasmine-core": "~5.1.2",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.38",
    "sinon": "^18.0.0",
    "stylelint": "^16.3.1",
    "stylelint-config-sass-guidelines": "^11.1.0",
    "stylelint-config-standard-scss": "^13.1.0",
    "stylelint-scss": "^6.2.1",
    "typescript": "~5.4.5"
  }
}
