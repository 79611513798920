"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.countryCodes = exports.countryList = exports.KycTypeMap = exports.KycType = exports.riskLevels = exports.propertyTitles = exports.valueTitles = exports.ADMIN_ROLE = void 0;
exports.ADMIN_ROLE = 'savingsAccountAdmin';
exports.valueTitles = {
  // PEP
  notPep: 'Inte PEP eller RCA',
  pep: 'Är PEP',
  rca: 'Anhörig till PEP',
  // pepTitles
  headOfGovernment: 'Stats eller regeringschef',
  parliamentMember: 'Riksdagsledamot/Parlamentsledamot',
  politicalPartyBoardMember: 'Ledamot i styrelse för politiskt parti',
  judgeOrLegalOfficial: 'Domare i rättsligt organ',
  governmentAuditor: 'Hög tjänsteman vid revisionsmyndighet',
  ambassadorOrEnvoy: 'Ambassadör eller beskickningschef',
  seniorMilitaryOfficer: 'Officerare inom Försvarsmakten',
  stateOwnedCompanyDirector: 'VD eller styrelseledamot i ett statsägt företag',
  internationalOrganizationLeader: 'Ledande befattning i en internationell organisation',
  // RCA Relations
  spouse: 'Make/maka',
  registeredPartner: 'Registrerad partner',
  cohabitant: 'Sambo',
  child: 'Barn',
  childSpouseCohabitant: 'Barns make/maka, sambo eller partner',
  parent: 'Förälder',
  knownCloseAssociate: 'Känd/Nära medarbetare',
  closeBusinessAssociate: 'Nära affärsförbindelse',
  // Tax residency
  workingAbroad: 'Arbetar utomlands',
  propertyAbroad: 'Fastighet eller lägenhet i utlandet',
  other: 'Annan',
  none: 'Ingen',
  // Interest
  '3': '3 månader',
  '3-auto': '3 månader med automatisk ombindning',
  '6': '6 månader',
  '12': '1 år',
  '18': '1,5 år',
  '24': '2 år',
  '36': '3 år',
  '48': '4 år',
  '60': '5 år'
};
exports.propertyTitles = {
  // Applicant Form
  name: 'Namn',
  personalNumber: 'Personnummer',
  countryCode: 'Landskod',
  phone: 'Mobilnummer',
  email: 'Email',
  postalCode: 'Postnummer',
  streetAdress: 'Gatuadress',
  streetNumber: 'Gatunummer',
  city: 'Ort',
  // Birth Form
  birthCountry: 'Födelseland',
  birthCity: 'Födelseort',
  citizenship: 'Medborgarskap',
  // PrimaryResidence Form
  primaryResidence: 'Primärt boende',
  // GreenCard Form
  greenCard: 'Green card',
  // AmericanCitizenship Form
  americanCitizenship: 'Amerikansk medborgare och/eller skatterättslig hemvist i USA',
  // TaxResidency Form
  taxResidencySwedish: 'Skatterättslig hemvist i Sverige',
  taxResidencyNotSwedish: 'Skatterättslig hemvist i andra länder',
  notUsaCitizenCheckbox: 'Intygar ej amerikansk medborgare',
  onlySwedenTaxCheckbox: 'Intygar skatterättslig hemvist i Sverige',
  taxResidencyCountry: 'Skatterättslig hemvist',
  taxResidencyRelation: 'Relation till landet',
  taxResidencyTin: 'Skatteregistreringsnummer/TIN',
  taxResidencyOtherRelation: 'Annan relation till landet',
  // Pep Form
  pepStatus: 'PEP',
  pepTitle: 'Arbetstitel',
  pepPosition: 'Arbetsposition',
  pepCountry: 'Land för PEP',
  pepRelation: 'Relation till PEP',
  pepOtherRelation: 'Ytterligare PEP relation',
  pepOtherRelationInfo: 'Ytterligare PEP relation info',
  pepOtherWork: 'Ytterligare PEP arbete',
  pepOtherWorkInfo: 'Ytterligare PEP arbete info',
  // BankRelationship Form
  openSavingsAccount: 'Vill öppna ett sparkonto',
  openFixedRateAccount: 'Vill öppna ett fasträntekonto',
  existingSavingsAccount: 'Använd befintligt konto',
  existingSavingsAccountNumber: 'Befintligt konto',
  interestRunningTime: 'Löptid fastränteplacering',
  interestPlacementAmount: 'Fastränteplacering belopp',
  wantToBeContacted: 'Vill bli kontaktad',
  // Income Form
  salary: 'Lön',
  pension: 'Pension',
  benefits: 'Bidrag eller arbetslöshetsersättning',
  studentAid: 'Studiebidrag och/eller studielån',
  soleProprietorship: 'Inkomst från enskild firma',
  capital: 'Kapital',
  other: 'Annan',
  // Savings Form
  savingsTotal: 'Totalt sparat kapital',
  savingsSource: 'Var kommer sparat kapital ifrån'
};
exports.riskLevels = {
  'normal': 'Normal',
  'high': 'Hög'
};
var KycType;
(function (KycType) {
  KycType[KycType["NONE"] = -1] = "NONE";
  KycType[KycType["BIRTH"] = 0] = "BIRTH";
  KycType[KycType["PRIMARY_RESIDENCE"] = 1] = "PRIMARY_RESIDENCE";
  KycType[KycType["GREEN_CARD"] = 2] = "GREEN_CARD";
  KycType[KycType["AMERICAN_CITIZENSHIP"] = 3] = "AMERICAN_CITIZENSHIP";
  KycType[KycType["TAX_RESIDENCY"] = 4] = "TAX_RESIDENCY";
  KycType[KycType["PEP"] = 5] = "PEP";
  KycType[KycType["BANK_RELATIONSHIP"] = 6] = "BANK_RELATIONSHIP";
  KycType[KycType["INCOME"] = 7] = "INCOME";
  KycType[KycType["SAVINGS"] = 8] = "SAVINGS";
})(KycType || (exports.KycType = KycType = {}));
exports.KycTypeMap = new Map([[KycType.BIRTH, 'birth'], [KycType.PRIMARY_RESIDENCE, 'primaryResidence'], [KycType.GREEN_CARD, 'greenCard'], [KycType.AMERICAN_CITIZENSHIP, 'americanCitizenship'], [KycType.TAX_RESIDENCY, 'taxResidency'], [KycType.PEP, 'pep'], [KycType.BANK_RELATIONSHIP, 'bankRelationship'], [KycType.INCOME, 'income'], [KycType.SAVINGS, 'savings']]);
exports.countryList = [{
  name: 'Sverige',
  2: 'SE',
  3: 'SWE',
  n: 752
}, {
  name: 'Afghanistan',
  2: 'AF',
  3: 'AFG',
  n: 4
}, {
  name: 'Albanien',
  2: 'AL',
  3: 'ALB',
  n: 8
}, {
  name: 'Algeriet',
  2: 'DZ',
  3: 'DZA',
  n: 12
}, {
  name: 'Amerikanska Samoa',
  2: 'AS',
  3: 'ASM',
  n: 16
}, {
  name: 'Andorra',
  2: 'AD',
  3: 'AND',
  n: 20
}, {
  name: 'Angola',
  2: 'AO',
  3: 'AGO',
  n: 24
}, {
  name: 'Anguilla',
  2: 'AI',
  3: 'AIA',
  n: 660
}, {
  name: 'Antarktis',
  2: 'AQ',
  3: 'ATA',
  n: 10
}, {
  name: 'Antigua och Barbuda',
  2: 'AG',
  3: 'ATG',
  n: 28
}, {
  name: 'Argentina',
  2: 'AR',
  3: 'ARG',
  n: 32
}, {
  name: 'Armenien',
  2: 'AM',
  3: 'ARM',
  n: 51
}, {
  name: 'Aruba',
  2: 'AW',
  3: 'ABW',
  n: 533
}, {
  name: 'Australien',
  2: 'AU',
  3: 'AUS',
  n: 36
}, {
  name: 'Azerbajdzjan',
  2: 'AZ',
  3: 'AZE',
  n: 31
}, {
  name: 'Bahamas',
  2: 'BS',
  3: 'BHS',
  n: 44
}, {
  name: 'Bahrain',
  2: 'BH',
  3: 'BHR',
  n: 48
}, {
  name: 'Bangladesh',
  2: 'BD',
  3: 'BGD',
  n: 50
}, {
  name: 'Barbados',
  2: 'BB',
  3: 'BRB',
  n: 52
}, {
  name: 'Belgien',
  2: 'BE',
  3: 'BEL',
  n: 56
}, {
  name: 'Belize',
  2: 'BZ',
  3: 'BLZ',
  n: 84
}, {
  name: 'Benin',
  2: 'BJ',
  3: 'BEN',
  n: 204
}, {
  name: 'Bermuda',
  2: 'BM',
  3: 'BMU',
  n: 60
}, {
  name: 'Bhutan',
  2: 'BT',
  3: 'BTN',
  n: 64
}, {
  name: 'Bolivia',
  2: 'BO',
  3: 'BOL',
  n: 68
}, {
  name: 'Bosnien-Hercegovina',
  2: 'BA',
  3: 'BIH',
  n: 70
}, {
  name: 'Botswana',
  2: 'BW',
  3: 'BWA',
  n: 72
}, {
  name: 'Bouvetön',
  2: 'BV',
  3: 'BVT',
  n: 74
}, {
  name: 'Brasilien',
  2: 'BR',
  3: 'BRA',
  n: 76
}, {
  name: 'Brittiska Jungfruöarna',
  2: 'VG',
  3: 'VGB',
  n: 92
}, {
  name: 'Brittiska territoriet i Indiska Oceanen',
  2: 'IO',
  3: 'IOT',
  n: 86
}, {
  name: 'Brunei',
  2: 'BN',
  3: 'BRN',
  n: 96
}, {
  name: 'Bulgarien',
  2: 'BG',
  3: 'BGR',
  n: 100
}, {
  name: 'Burkina Faso',
  2: 'BF',
  3: 'BFA',
  n: 854
}, {
  name: 'Burma (Myanmar)',
  2: 'MM',
  3: 'MMR',
  n: 104
}, {
  name: 'Burundi',
  2: 'BI',
  3: 'BDI',
  n: 108
}, {
  name: 'Caymanöarna',
  2: 'KY',
  3: 'KYM',
  n: 136
}, {
  name: 'Centralafrikanska republiken',
  2: 'CF',
  3: 'CAF',
  n: 140
}, {
  name: 'Chile',
  2: 'CL',
  3: 'CHL',
  n: 152
}, {
  name: 'Colombia',
  2: 'CO',
  3: 'COL',
  n: 170
}, {
  name: 'Comorerna',
  2: 'KM',
  3: 'COM',
  n: 174
}, {
  name: 'Cooköarna',
  2: 'CK',
  3: 'COK',
  n: 184
}, {
  name: 'Costa Rica',
  2: 'CR',
  3: 'CRI',
  n: 188
}, {
  name: 'Cypern',
  2: 'CY',
  3: 'CYP',
  n: 196
}, {
  name: 'Danmark',
  2: 'DK',
  3: 'DNK',
  n: 208
}, {
  name: 'Djibouti',
  2: 'DJ',
  3: 'DJI',
  n: 262
}, {
  name: 'Dominica',
  2: 'DM',
  3: 'DMA',
  n: 212
}, {
  name: 'Dominikanska republiken',
  2: 'DO',
  3: 'DOM',
  n: 214
}, {
  name: 'Ecuador',
  2: 'EC',
  3: 'ECU',
  n: 218
}, {
  name: 'Egypten',
  2: 'EG',
  3: 'EGY',
  n: 818
}, {
  name: 'Ekvatorialguinea',
  2: 'GQ',
  3: 'GNQ',
  n: 226
}, {
  name: 'El Salvador',
  2: 'SV',
  3: 'SLV',
  n: 222
}, {
  name: 'Elfenbenskusten',
  2: 'CI',
  3: 'CIV',
  n: 384
}, {
  name: 'Eritrea',
  2: 'ER',
  3: 'ERI',
  n: 232
}, {
  name: 'Estland',
  2: 'EE',
  3: 'EST',
  n: 233
}, {
  name: 'Etiopien',
  2: 'ET',
  3: 'ETH',
  n: 210
}, {
  name: 'Falklandsöarna',
  2: 'FK',
  3: 'FLK',
  n: 238
}, {
  name: 'Färöarna',
  2: 'FO',
  3: 'FRO',
  n: 234
}, {
  name: 'Fiji',
  2: 'FJ',
  3: 'FJI',
  n: 242
}, {
  name: 'Filippinerna',
  2: 'PH',
  3: 'PHL',
  n: 608
}, {
  name: 'Finland',
  2: 'FI',
  3: 'FIN',
  n: 246
}, {
  name: 'Frankrike',
  2: 'FR',
  3: 'FRA',
  n: 250
}, {
  name: 'Frankrike (europeiska)',
  2: 'FX',
  3: 'FXX',
  n: 249
}, {
  name: 'Franska Guyana',
  2: 'GF',
  3: 'GUF',
  n: 254
}, {
  name: 'Franska Polynesien',
  2: 'PF',
  3: 'PYF',
  n: 258
}, {
  name: 'Franska södra territorierna',
  2: 'TF',
  3: 'ATF',
  n: 260
}, {
  name: 'Förenade arabemiraten',
  2: 'AE',
  3: 'ARE',
  n: 784
}, {
  name: 'Gabon',
  2: 'GA',
  3: 'GAB',
  n: 266
}, {
  name: 'Gambia',
  2: 'GM',
  3: 'GMB',
  n: 270
}, {
  name: 'Georgien',
  2: 'GE',
  3: 'GEO',
  n: 268
}, {
  name: 'Ghana',
  2: 'GH',
  3: 'GHA',
  n: 288
}, {
  name: 'Gibraltar',
  2: 'GI',
  3: 'GIB',
  n: 292
}, {
  name: 'Grönland',
  2: 'GL',
  3: 'GRL',
  n: 304
}, {
  name: 'Grekland',
  2: 'GR',
  3: 'GRC',
  n: 300
}, {
  name: 'Grenada',
  2: 'GD',
  3: 'GRD',
  n: 308
}, {
  name: 'Guadeloupe',
  2: 'GP',
  3: 'GLP',
  n: 312
}, {
  name: 'Guam',
  2: 'GU',
  3: 'GUM',
  n: 316
}, {
  name: 'Guatemala',
  2: 'GT',
  3: 'GTM',
  n: 320
}, {
  name: 'Guinea',
  2: 'GN',
  3: 'GIN',
  n: 324
}, {
  name: 'Guinea Bissau',
  2: 'GW',
  3: 'GNB',
  n: 624
}, {
  name: 'Guyana',
  2: 'GY',
  3: 'GUY',
  n: 328
}, {
  name: 'Haiti',
  2: 'HT',
  3: 'HTI',
  n: 332
}, {
  name: 'Heard- och McDonaldsöarna',
  2: 'HM',
  3: 'HMD',
  n: 334
}, {
  name: 'Honduras',
  2: 'HN',
  3: 'HND',
  n: 340
}, {
  name: 'Hongkong',
  2: 'HK',
  3: 'HKG',
  n: 344
}, {
  name: 'Indien',
  2: 'IN',
  3: 'IND',
  n: 356
}, {
  name: 'Indonesien',
  2: 'ID',
  3: 'IDN',
  n: 360
}, {
  name: 'Irak',
  2: 'IQ',
  3: 'IRQ',
  n: 368
}, {
  name: 'Iran',
  2: 'IR',
  3: 'IRN',
  n: 364
}, {
  name: 'Irland',
  2: 'IE',
  3: 'IRL',
  n: 372
}, {
  name: 'Island',
  2: 'IS',
  3: 'ISL',
  n: 352
}, {
  name: 'Israel',
  2: 'IL',
  3: 'ISR',
  n: 376
}, {
  name: 'Italien',
  2: 'IT',
  3: 'ITA',
  n: 380
}, {
  name: 'Jamaica',
  2: 'JM',
  3: 'JAM',
  n: 388
}, {
  name: 'Japan',
  2: 'JP',
  3: 'JPN',
  n: 392
}, {
  name: 'Jordanien',
  2: 'JO',
  3: 'JOR',
  n: 400
}, {
  name: 'Julön',
  2: 'CX',
  3: 'CXR',
  n: 162
}, {
  name: 'Jungfruöarna USA',
  2: 'VI',
  3: 'VIR',
  n: 850
}, {
  name: 'Kambodja',
  2: 'KH',
  3: 'KHM',
  n: 116
}, {
  name: 'Kamerun',
  2: 'CM',
  3: 'CMR',
  n: 120
}, {
  name: 'Kanada',
  2: 'CA',
  3: 'CAN',
  n: 124
}, {
  name: 'Kap Verde',
  2: 'CV',
  3: 'CPV',
  n: 132
}, {
  name: 'Kazakstan',
  2: 'KZ',
  3: 'KAZ',
  n: 398
}, {
  name: 'Kenya',
  2: 'KE',
  3: 'KEN',
  n: 404
}, {
  name: 'Kina',
  2: 'CN',
  3: 'CHN',
  n: 156
}, {
  name: 'Kirgizistan',
  2: 'KG',
  3: 'KGZ',
  n: 417
}, {
  name: 'Kiribati',
  2: 'KI',
  3: 'KIR',
  n: 296
}, {
  name: 'Kokosöarna',
  2: 'CC',
  3: 'CCK',
  n: 166
}, {
  name: 'Kongo',
  2: 'CG',
  3: 'COG',
  n: 178
}, {
  name: 'Kosovo',
  2: 'XK',
  3: 'XXK',
  n: 900
}, {
  name: 'Kroatien',
  2: 'HR',
  3: 'HRV',
  n: 191
}, {
  name: 'Kuba',
  2: 'CU',
  3: 'CUB',
  n: 192
}, {
  name: 'Kuwait',
  2: 'KW',
  3: 'KWT',
  n: 414
}, {
  name: 'Laos',
  2: 'LA',
  3: 'LAO',
  n: 418
}, {
  name: 'Lesotho',
  2: 'LS',
  3: 'LSO',
  n: 426
}, {
  name: 'Lettland',
  2: 'LV',
  3: 'LVA',
  n: 428
}, {
  name: 'Libanon',
  2: 'LB',
  3: 'LBN',
  n: 422
}, {
  name: 'Liberia',
  2: 'LR',
  3: 'LBR',
  n: 430
}, {
  name: 'Libyen',
  2: 'LY',
  3: 'LBY',
  n: 434
}, {
  name: 'Liechtenstein',
  2: 'LI',
  3: 'LIE',
  n: 438
}, {
  name: 'Litauen',
  2: 'LT',
  3: 'LTU',
  n: 440
}, {
  name: 'Luxemburg',
  2: 'LU',
  3: 'LUX',
  n: 442
}, {
  name: 'Macau',
  2: 'MO',
  3: 'MAC',
  n: 446
}, {
  name: 'Madagaskar',
  2: 'MG',
  3: 'MDG',
  n: 450
}, {
  name: 'Makedonien',
  2: 'MK',
  3: 'MKD',
  n: 807
}, {
  name: 'Malawi',
  2: 'MW',
  3: 'MWI',
  n: 454
}, {
  name: 'Malaysia',
  2: 'MY',
  3: 'MYS',
  n: 458
}, {
  name: 'Maldiverna',
  2: 'MV',
  3: 'MDV',
  n: 462
}, {
  name: 'Mali',
  2: 'ML',
  3: 'MLI',
  n: 466
}, {
  name: 'Malta',
  2: 'MT',
  3: 'MLT',
  n: 470
}, {
  name: 'Marocko',
  2: 'MA',
  3: 'MAR',
  n: 504
}, {
  name: 'Marshallöarna',
  2: 'MH',
  3: 'MHL',
  n: 584
}, {
  name: 'Martinique',
  2: 'MQ',
  3: 'MTQ',
  n: 474
}, {
  name: 'Mauretanien',
  2: 'MR',
  3: 'MRT',
  n: 478
}, {
  name: 'Mauritius',
  2: 'MU',
  3: 'MUS',
  n: 480
}, {
  name: 'Mayotte',
  2: 'YT',
  3: 'MYT',
  n: 175
}, {
  name: 'Mexiko',
  2: 'MX',
  3: 'MEX',
  n: 484
}, {
  name: 'Mikronesiens federerade stater',
  2: 'FM',
  3: 'FSM',
  n: 583
}, {
  name: 'Mocambique',
  2: 'MZ',
  3: 'MOZ',
  n: 508
}, {
  name: 'Moldavien',
  2: 'MD',
  3: 'MDA',
  n: 498
}, {
  name: 'Monaco',
  2: 'MC',
  3: 'MCO',
  n: 492
}, {
  name: 'Mongoliet',
  2: 'MN',
  3: 'MNG',
  n: 496
}, {
  name: 'Montenegro',
  2: 'ME',
  3: 'MNE',
  n: 499
}, {
  name: 'Montserrat',
  2: 'MS',
  3: 'MSR',
  n: 500
}, {
  name: 'Namibia',
  2: 'NA',
  3: 'NAM',
  n: 516
}, {
  name: 'Nauru',
  2: 'NR',
  3: 'NRU',
  n: 520
}, {
  name: 'Nederländerna',
  2: 'NL',
  3: 'NLD',
  n: 528
}, {
  name: 'Nederländska Antillerna',
  2: 'AN',
  3: 'ANT',
  n: 530
}, {
  name: 'Nepal',
  2: 'NP',
  3: 'NPL',
  n: 524
}, {
  name: 'Nicaragua',
  2: 'NI',
  3: 'NIC',
  n: 558
}, {
  name: 'Niger',
  2: 'NE',
  3: 'NER',
  n: 562
}, {
  name: 'Nigeria',
  2: 'NG',
  3: 'NGA',
  n: 566
}, {
  name: 'Niue',
  2: 'NU',
  3: 'NIU',
  n: 570
}, {
  name: 'Nordkorea',
  2: 'KP',
  3: 'PRK',
  n: 408
}, {
  name: 'Nordmarianerna',
  2: 'MP',
  3: 'MNP',
  n: 580
}, {
  name: 'Norfolkön',
  2: 'NF',
  3: 'NFK',
  n: 574
}, {
  name: 'Norge',
  2: 'NO',
  3: 'NOR',
  n: 578
}, {
  name: 'Nya Kaledonien',
  2: 'NC',
  3: 'NCL',
  n: 540
}, {
  name: 'Nya Zeeland',
  2: 'NZ',
  3: 'NZL',
  n: 554
}, {
  name: 'Oman',
  2: 'OM',
  3: 'OMN',
  n: 512
}, {
  name: 'Pakistan',
  2: 'PK',
  3: 'PAK',
  n: 586
}, {
  name: 'Palau',
  2: 'PW',
  3: 'PLW',
  n: 585
}, {
  name: 'Panama',
  2: 'PA',
  3: 'PAN',
  n: 591
}, {
  name: 'Papua Nya Guinea',
  2: 'PG',
  3: 'PNG',
  n: 598
}, {
  name: 'Paraguay',
  2: 'PY',
  3: 'PRY',
  n: 600
}, {
  name: 'Peru',
  2: 'PE',
  3: 'PER',
  n: 604
}, {
  name: 'Pitcairn',
  2: 'PN',
  3: 'PCN',
  n: 612
}, {
  name: 'Polen',
  2: 'PL',
  3: 'POL',
  n: 616
}, {
  name: 'Portugal',
  2: 'PT',
  3: 'PRT',
  n: 620
}, {
  name: 'Puerto Rico',
  2: 'PR',
  3: 'PRI',
  n: 630
}, {
  name: 'Qatar',
  2: 'QA',
  3: 'QAT',
  n: 634
}, {
  name: 'Reunion',
  2: 'RE',
  3: 'REU',
  n: 638
}, {
  name: 'Rumänien',
  2: 'RO',
  3: 'ROM',
  n: 642
}, {
  name: 'Rwanda',
  2: 'RW',
  3: 'RWA',
  n: 646
}, {
  name: 'Ryssland',
  2: 'RU',
  3: 'RUS',
  n: 643
}, {
  name: 'Saint Kitts och Nevis',
  2: 'KN',
  3: 'KNA',
  n: 659
}, {
  name: 'Saint Lucia',
  2: 'LC',
  3: 'LCA',
  n: 662
}, {
  name: 'Saint Pierre och Miquelon',
  2: 'PM',
  3: 'SPM',
  n: 666
}, {
  name: 'Saint Vincent och Grenadinerna',
  2: 'VC',
  3: 'VCT',
  n: 670
}, {
  name: 'Salomonöarna',
  2: 'SB',
  3: 'SLB',
  n: 90
}, {
  name: 'Samoa',
  2: 'WS',
  3: 'WSM',
  n: 882
}, {
  name: 'San Marino',
  2: 'SM',
  3: 'SMR',
  n: 674
}, {
  name: 'Sankta Helena',
  2: 'SH',
  3: 'SHN',
  n: 654
}, {
  name: 'Sao Tome och Principe',
  2: 'ST',
  3: 'STP',
  n: 678
}, {
  name: 'Saudiarabien',
  2: 'SA',
  3: 'SAU',
  n: 682
}, {
  name: 'Schweiz',
  2: 'CH',
  3: 'CHE',
  n: 756
}, {
  name: 'Senegal',
  2: 'SN',
  3: 'SEN',
  n: 686
}, {
  name: 'Serbien',
  2: 'RS',
  3: 'SRB',
  n: 688
}, {
  name: 'Seychellerna',
  2: 'SC',
  3: 'SYC',
  n: 690
}, {
  name: 'Sierra Leone',
  2: 'SL',
  3: 'SLE',
  n: 694
}, {
  name: 'Singapore',
  2: 'SG',
  3: 'SGP',
  n: 702
}, {
  name: 'Slovakien',
  2: 'SK',
  3: 'SVK',
  n: 703
}, {
  name: 'Slovenien',
  2: 'SI',
  3: 'SVN',
  n: 705
}, {
  name: 'Somalia',
  2: 'SO',
  3: 'SOM',
  n: 706
}, {
  name: 'Spanien',
  2: 'ES',
  3: 'ESP',
  n: 724
}, {
  name: 'Sri Lanka',
  2: 'LK',
  3: 'LKA',
  n: 144
}, {
  name: 'Storbritannien',
  2: 'GB',
  3: 'GBR',
  n: 826
}, {
  name: 'Sudan',
  2: 'SD',
  3: 'SDN',
  n: 736
}, {
  name: 'Surinam',
  2: 'SR',
  3: 'SUR',
  n: 740
}, {
  name: 'Svalbard och Jan Mayen',
  2: 'SJ',
  3: 'SJM',
  n: 744
}, {
  name: 'Sverige',
  2: 'SE',
  3: 'SWE',
  n: 752
}, {
  name: 'Swaziland',
  2: 'SZ',
  3: 'SWZ',
  n: 748
}, {
  name: 'Sydafrika',
  2: 'ZA',
  3: 'ZAF',
  n: 710
}, {
  name: 'Sydkorea',
  2: 'KR',
  3: 'KOR',
  n: 410
}, {
  name: 'Syrien',
  2: 'SY',
  3: 'SYR',
  n: 760
}, {
  name: 'Tadzjikistan',
  2: 'TJ',
  3: 'TJK',
  n: 762
}, {
  name: 'Taiwan',
  2: 'TW',
  3: 'TWN',
  n: 158
}, {
  name: 'Tanzania',
  2: 'TZ',
  3: 'TZA',
  n: 834
}, {
  name: 'Tchad',
  2: 'TD',
  3: 'TCD',
  n: 148
}, {
  name: 'Thailand',
  2: 'TH',
  3: 'THA',
  n: 764
}, {
  name: 'Tjeckien',
  2: 'CZ',
  3: 'CZE',
  n: 203
}, {
  name: 'Togo',
  2: 'TG',
  3: 'TGO',
  n: 768
}, {
  name: 'Tokelau',
  2: 'TK',
  3: 'TKL',
  n: 772
}, {
  name: 'Tonga',
  2: 'TO',
  3: 'TON',
  n: 776
}, {
  name: 'Trinidad och Tobago',
  2: 'TT',
  3: 'TTO',
  n: 780
}, {
  name: 'Tunisien',
  2: 'TN',
  3: 'TUN',
  n: 788
}, {
  name: 'Turkiet',
  2: 'TR',
  3: 'TUR',
  n: 792
}, {
  name: 'Turkmenistan',
  2: 'TM',
  3: 'TKM',
  n: 795
}, {
  name: 'Turks och Caicosöarna',
  2: 'TC',
  3: 'TCA',
  n: 796
}, {
  name: 'Tuvalu',
  2: 'TV',
  3: 'TUV',
  n: 798
}, {
  name: 'Tyskland',
  2: 'DE',
  3: 'DEU',
  n: 276
}, {
  name: 'Uganda',
  2: 'UG',
  3: 'UGA',
  n: 800
}, {
  name: 'Ukraina',
  2: 'UA',
  3: 'UKR',
  n: 804
}, {
  name: 'Ungern',
  2: 'HU',
  3: 'HUN',
  n: 348
}, {
  name: 'Uruguay',
  2: 'UY',
  3: 'URY',
  n: 858
}, {
  name: 'USA',
  2: 'US',
  3: 'USA',
  n: 840
}, {
  name: 'Uzbekistan',
  2: 'UZ',
  3: 'UZB',
  n: 860
}, {
  name: 'Vanuatu',
  2: 'VU',
  3: 'VUT',
  n: 548
}, {
  name: 'Vatikanstaten',
  2: 'VA',
  3: 'VAT',
  n: 336
}, {
  name: 'Västsahara',
  2: 'EH',
  3: 'ESH',
  n: 732
}, {
  name: 'Venezuela',
  2: 'VE',
  3: 'VEN',
  n: 862
}, {
  name: 'Vietnam',
  2: 'VN',
  3: 'VNM',
  n: 704
}, {
  name: 'Vitryssland',
  2: 'BY',
  3: 'BLR',
  n: 112
}, {
  name: 'Wallis- och Futunaöarna',
  2: 'WF',
  3: 'WLF',
  n: 876
}, {
  name: 'Yemen',
  2: 'YE',
  3: 'YEM',
  n: 887
}, {
  name: 'Yttre USA öarna',
  2: 'UM',
  3: 'UMI',
  n: 581
}, {
  name: 'Zaire',
  2: 'ZR',
  3: 'ZAR',
  n: 180
}, {
  name: 'Zambia',
  2: 'ZM',
  3: 'ZMB',
  n: 894
}, {
  name: 'Zimbabwe',
  2: 'ZW',
  3: 'ZWE',
  n: 716
}, {
  name: 'Österrike',
  2: 'AT',
  3: 'AUT',
  n: 40
}, {
  name: 'Östtimor',
  2: 'TP',
  3: 'TMP',
  n: 626
}];
exports.countryCodes = ['+46', '+30', '+31', '+32', '+33', '+34', '+36', '+37', '+39', '+40', '+41', '+43', '+44', '+45', '+47', '+48', '+49', '+350', '+351', '+352', '+353', '+354', '+355', '+356', '+357', '+358', '+359', '+370', '+371', '+372', '+373', '+374', '+375', '+376', '+377', '+378', '+379', '+380', '+381', '+382', '+383', '+385', '+386', '+387', '+389', '+420', '+421', '+422', '+423', '+424', '+425', '+426', '+427', '+428', '+429', '+500', '+501', '+502', '+503', '+504', '+505', '+506', '+507', '+508', '+509', '+51', '+52', '+53', '+54', '+55', '+56', '+57', '+58', '+590', '+591', '+592', '+593', '+594', '+595', '+596', '+597', '+598', '+599', '+60', '+61', '+62', '+63', '+64', '+65', '+66', '+670', '+671', '+672', '+673', '+674', '+675', '+676', '+677', '+678', '+679', '+680', '+681', '+682', '+683', '+685', '+686', '+687', '+688', '+689', '+690', '+691', '+692', '+693', '+694', '+695', '+696', '+697', '+698', '+699'];
