<form [formGroup]="taxResidencyForm">
  <h3>Skatterättslig hemvist</h3>
  <p>Skatterättslig hemvist är det land där en person betalar skatt och
    deklarerar sin inkomst, vanligtvis där personen bor eller arbetar och har
    skatteplikt enligt nationell lagstiftning.</p>
  <p>En person kan ha skatterättslig hemvist i flera länder.</p>

  <p class="select-title">Har du skatterättslig hemvist i Sverige?</p>
  <mat-radio-group
    color="primary"
    class="column"
    formControlName="taxResidencySwedish"
  >
    <mat-radio-button [value]="true">Ja</mat-radio-button>
    <mat-radio-button [value]="false">Nej</mat-radio-button>
  </mat-radio-group>

  <div>
    <p class="select-title">Har du skatterättslig hemvist i andra länder?</p>
    <mat-radio-group
      color="primary"
      class="column"
      formControlName="taxResidencyNotSwedish">
      <mat-radio-button [value]="true">Ja</mat-radio-button>
      <mat-radio-button [value]="false">Nej</mat-radio-button>
    </mat-radio-group>
  </div>

  @if (checkIfSwedenOnly()) {
    <div [ngClass]="{ 'margin-bottom-medium': checkIfUsaPerson() }">
    <p class="select-title">Då du angett primärt boende utanför Sverige, behöver
      du
      intyga att du inte har skatterättslig hemvist i annat land än Sverige.</p>
    <mat-checkbox
      color="primary"
      class="column"
      formControlName="onlySwedenTaxCheckbox">Jag intygar att jag inte har
      skatterättslig hemvist i annat land än Sverige
    </mat-checkbox>
    </div>
  }

  @if (taxResidencyForm.controls.taxResidencyNotSwedish.value === false && checkIfUsaPerson()) {
    <p>Då du angett en födelseort i USA, medborgarskap i
      USA och/eller har ett amerikanskt uppehålls- och arbetstillstånd är du
      troligen skattskyldig i USA.
      Om detta stämmer, vänligen ändra ditt svar till Ja och ange sedan USA som
      skatterättslig hemvist och TIN.</p>
    <p class="select-title">Om du har upphört att vara amerikansk medborgare,
      behöver du intyga
      detta.
    </p>
    <mat-checkbox
      color="primary"
      class="column"
      formControlName="notUsaCitizenCheckbox">Jag intygar att jag upphört att
      vara amerikansk medborgare
    </mat-checkbox>
  }

  @if (taxResidencyForm.controls.taxResidencyNotSwedish.value) {
    <p>Banken har skyldighet att fråga om uppgifter till följd av avtal om
      informationsinhämtning gällande FATCA/CRS. Läs mer om detta <a
        href="https://www.swedishbankers.se/media/3531/1710_crs.pdf"
        target="_blank">här.</a> Läs mer om respektive lands
      skatteregistreringsnummer/TIN <a
        href="https://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-identification-numbers/#d.en.347759"
        target="_blank">här.</a></p>


    <div
      [class.small-screen]="orientationService.isSmallScreen$()"
    >
      @for (formGroup of formGroups; track $index) {
        <p>Vänligen ange i vilket land du har skatterättslig hemvist
          samt skatteregistreringsnummer/TIN och din relation till landet.</p>

        @if ($index === 0) {
          <p>Om du har skatterättslig hemvist i flera länder, klicka på "Lägg
            till fler" nedan.</p>
        }
        <div [formGroup]="formGroup">
          <div class="select-country">
            <mat-form-field
              [class.small-screen]="orientationService.isSmallScreen$()"
            >
              <mat-label>Välj land</mat-label>
              <mat-select
                formControlName="taxResidencyCountry"
              >
                @for (country of countryList; track $index) {
                  <mat-option
                    [value]="country.name">{{ country.name }}
                  </mat-option>
                }
              </mat-select>
              <mat-error>Vänligen gör ett val</mat-error>
            </mat-form-field>
            @if ($index !== 0 && $index === (formGroups.length - 1)) {
              <button mat-button (click)="removeControl($index)">- Ta bort
              </button>
            }
          </div>
          <div>
            <mat-form-field>
              <mat-label>Ange Skatteregistreringsnummer/TIN</mat-label>
              <input
                formControlName="taxResidencyTin"
                matInput type="text">
              @if (formGroup.controls.taxResidencyTin.touched) {
                <mat-error>Vänligen gör ett val</mat-error>
              }
            </mat-form-field>
          </div>

          <p class="select-title">Din relation till landet.</p>
          <div class="column" [style.margin-bottom.px]="20">
            @for (option of workingAbroadOptions; track $index) {
              <mat-checkbox
                color="primary"
                [checked]="option.value === formGroup.controls.taxResidencyRelation.value"
                (change)="onCheckboxChange(option.value, 'taxResidencyRelation', $event.checked,formGroup)">
                {{ option.label }}
              </mat-checkbox>

              @if (option.value === 'other' &&
              formGroup.controls.taxResidencyRelation.value === 'other') {
                <div>
                  <mat-form-field>
                    <mat-label>Fritext</mat-label>
                    <input placeholder="Ange din relation till landet"
                           formControlName="taxResidencyOtherRelation"
                           matInput
                           type="text">
                    @if (formGroup.controls.taxResidencyOtherRelation.touched) {
                      <mat-error>Vänligen skriv här</mat-error>
                    }
                  </mat-form-field>
                </div>
              }
            }
          </div>
        </div>
      }
      <button mat-raised-button (click)="addControlsToFormGroup()">
        <div class="centered-container">
          <span class="material-symbols-outlined">add</span>
          <span>Lägg ytterligare ett land</span>
        </div>
      </button>
    </div>
  }
</form>