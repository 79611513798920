import {Component, Input} from '@angular/core'
import {FormGroup} from '@angular/forms'
import {SavingsAccountService} from '../../../services/savings-account.service'

@Component({
  selector: 'spb-income',
  templateUrl: './income.component.html',
  styleUrl: '../kyc.component.scss'
})
export class IncomeComponent {
  @Input({required: true}) incomeForm!: FormGroup

  constructor(protected savingsAccountService: SavingsAccountService) {
  }

  public onCheckboxChange(index: number, checked: boolean, formValue: string) {
    this.savingsAccountService.incomeOptions[index].checked = checked
    if(!checked){
      this.incomeForm.controls[formValue].setValue(null)
    }
  }
}
