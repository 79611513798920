<h3>Kontaktuppgifter</h3>
<form [formGroup]="applicantsForm">
  <p>Vänligen ange dina kontaktuppgifter</p>
  <div class="form-field-group flex-1-to-1"
       [class.small-screen]="orientationService.isSmallScreen$()">
    <mat-form-field
      class="disabled-gray-background"
      color="warn">
      <mat-label>Personnummer</mat-label>
      <input readonly formControlName="personalNumber" matInput type="text"
             spbPersonnummerValidator>
    </mat-form-field>

    <mat-form-field
      [ngClass]="{'disabled-gray-background': !savingsAccountService.applicant$().sparFailed}"
      color="warn">
      <mat-label>Namn</mat-label>
      <input [readonly]="!savingsAccountService.applicant$().sparFailed"
             formControlName="name" matInput type="text">
    </mat-form-field>

  </div>

  <div class="form-field-group flex-3-to-1"
       [class.small-screen]="orientationService.isSmallScreen$()">
    <mat-form-field
      [ngClass]="{'disabled-gray-background': !savingsAccountService.applicant$().sparFailed}"
      color="warn"
    >
      <mat-label>Gata</mat-label>
      <input [readonly]="!savingsAccountService.applicant$().sparFailed"
             formControlName="streetAdress" matInput type="text">
    </mat-form-field>
    <mat-form-field
      [ngClass]="{'disabled-gray-background': !savingsAccountService.applicant$().sparFailed}"
      color="warn"
    >
      <mat-label>Nummer</mat-label>
      <input [readonly]="!savingsAccountService.applicant$().sparFailed"
             formControlName="streetNumber" matInput type="text">
    </mat-form-field>
  </div>

  <div class="form-field-group flex-1-to-3"
       [class.small-screen]="orientationService.isSmallScreen$()">
    <mat-form-field
      [ngClass]="{'disabled-gray-background': !savingsAccountService.applicant$().sparFailed}"
      color="warn"
    >
      <mat-label>Postnummer</mat-label>
      <input
        [readonly]="!savingsAccountService.applicant$().sparFailed"
        inputmode="numeric"
        formControlName="postalCode"
        matInput
        type="text"
        maxlength="6"
      >
    </mat-form-field>
    <mat-form-field
      [ngClass]="{'disabled-gray-background': !savingsAccountService.applicant$().sparFailed}"
      color="warn"
    >
      <mat-label>Ort</mat-label>
      <input
        [readonly]="!savingsAccountService.applicant$().sparFailed"
        formControlName="city" matInput type="text">
    </mat-form-field>
  </div>

  <div class="form-field-group flex-1-to-3"
       [class.small-screen]="orientationService.isSmallScreen$()">
    <mat-form-field
      class="disabled-gray-background"
      color="warn"
    >
      <mat-label>Landskod</mat-label>
      <input matInput formControlName="countryCode" readonly value="+46">
      <mat-error>Ange landskod</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Mobilnummer</mat-label>
      <input formControlName="phone"
             inputmode="numeric"
             matInput type="tel"
             (blur)="formatPhone(applicantsForm.controls.phone)">
      <mat-error>{{ applicantsForm.controls.phone.errors?.error.message }}</mat-error>
    </mat-form-field>
  </div>

  <mat-form-field>
    <mat-label>E-post</mat-label>
    <input formControlName="email" matInput type="text">
    <mat-error>Ange en korrekt e-postadress</mat-error>
  </mat-form-field>

</form>
