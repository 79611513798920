<div class="complete-holder">
  <div class="text-holder">
    <h3>Tack! Din ansökan är nu påbörjad.</h3>

    <p>
      Vi kommer att gå igenom dina uppgifter och kontaktar dig via e-post inom
      24
      timmar (gäller bankdagar).
    </p>

    <p>
      Har du frågor kring din ansökan är du välkommen att kontakta vårt
      kundcenter. Du kan antingen skicka ett e-postmeddelande till <a
      href="mailto:info@sparbankensyd.se">info&#64;sparbankensyd.se</a> eller
      ringa oss på 0411-82 20 00.
    </p>
  </div>
</div>
