@if (savingsAccountService.testMode$() || savingsAccountService.applicant$().personalNumber) {
  <spb-form></spb-form>
} @else {
  <div class="center-ftw">
    <div class="text-container mat-elevation-z8">
      @if (notAdult) {
        <h3>Hej!</h3>
        <div [style.max-width.px]="500">
          <p>
            Vi ser att du är intresserad av vår tjänst som endast är tillgänglig
            för personer över 18 år.
          </p>
          <p>
            Vi vill gärna erbjuda dig andra möjligheter och föreslår vårt Ung i
            Syd-paket. Där kan du hitta information om produkter och tjänster
            som är speciellt utformade för unga. Vi hoppas att du hittar det du
            söker!
          </p>
          <p>

            Om du har några frågor eller behöver ytterligare information är du
            välkommen att kontakta oss.
          </p>
        </div>

        <div class="button">
          <a
            href="https://www.sparbankensyd.se/privat/vara-erbjudanden/vara-paketerbjudanden/ung-i-syd"
            target="_blank">
            <button mat-raised-button color="primary">Ung i Syd</button>
          </a>
        </div>
      } @else {
        <h3>Vad roligt att du vill öppna ett konto!</h3>
        <p>För att gå vidare behöver du ha ett BankID.</p>
        <p>Saknar du ett BankID är du välkommen att besöka ett av våra <a
          href="https://www.sparbankensyd.se/privat/kontakt"
          target="_blank">kontor</a>.</p>

        <div class="bankid-buttons">
          <spb-bankid
            [domain]="domain"
            customButton="Öppna BankID"
            mode="login"
            [bankIdUrl]="authServiceUrl"
            (accessToken)="setToken($event)"
            (click)="this.savingsAccountService.sessionTimeout$.set(false)"
          ></spb-bankid>
        </div>
      }
      @if (savingsAccountService.sessionTimeout$()) {
        <div class="session-timeout">
          Ansökan avbröts. Vänligen logga in igen för att påbörja en ny ansökan.
        </div>
      }
    </div>
  </div>
}


