<form [formGroup]="savingsForm">
  <h3>Min ekonomi - Sparande</h3>
  <p>Hur stort är ditt totala sparade kapital i andra banker/institut?</p>
  <mat-form-field>
    <mat-label>Totalt sparat kapital, belopp i SEK</mat-label>
    <input
      inputmode="numeric"
      matInput formControlName="savingsTotal" spbFormatNumber
    >
  </mat-form-field>

  <div class="margin-bottom-medium column">
    <p class="select-title">Var kommer ditt sparade kapital ifrån?</p>
    @for (option of savingsAccountService.savingsSource; track $index) {
      <div class="margin-bottom-small"
           [class.small-screen]="orientationService.isSmallScreen$()">
      <mat-checkbox
        color="primary"
        (change)="onCheckboxChange($index, $event.checked)">
        {{ option.value }}
      </mat-checkbox>
      </div>
    }
  </div>
</form>
