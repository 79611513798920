<form [formGroup]="incomeForm">
  <h3>Min ekonomi</h3>
  <p>Var kommer pengarna ifrån och vilket belopp vill du sätta in på ditt sparande hos oss det närmaste året?</p>

  <div class="column flex-start">
    @for (option of savingsAccountService.incomeOptions; track $index) {
      <mat-checkbox
        color="primary"
        [checked]="option.checked"
        (change)="onCheckboxChange($index, $event.checked, option.value)"
      >
        {{ option.label }}
      </mat-checkbox>

      @if (option.checked) {
        <mat-form-field>
          <mat-label>Årligt belopp i SEK (efter skatt)</mat-label>
          <input
            inputmode="numeric"
            spbFormatNumber
            [formControlName]="option.value" matInput type="text">
          <mat-error>Vänligen ange födelseort</mat-error>
        </mat-form-field>
      }
    }
  </div>
</form>
