import {Component, Input, OnInit} from '@angular/core'
import {AbstractControl, FormGroup} from '@angular/forms'
import {OrientationService} from '../../services/orientation.service'
import {SavingsAccountService} from '../../services/savings-account.service'


const formatPhone = (phone: string): string => {
  // Remove all non-digit characters
  const onlyDigits = phone.replace(/\D/g, '')

  // Check if the first digit is 0 and remove it if true
  if (onlyDigits.startsWith('0')) {
    return onlyDigits.slice(1) // Return the number without the first digit
  }

  // If there's no 0 at the beginning, return the number as is
  return onlyDigits
}


@Component({
  selector: 'spb-applicants',
  templateUrl: './applicants.component.html',
  styleUrl: './applicants.component.scss'
})
export class ApplicantsComponent implements OnInit {
  @Input({required: true}) applicantsForm!: FormGroup

  constructor(
    protected savingsAccountService: SavingsAccountService,
    protected orientationService: OrientationService
  ) {
  }

  public ngOnInit() {
    this.applicantsForm.controls.postalCode.valueChanges.subscribe(v => {
      if (v) {
        const formattedValue = this.formatPostalCode(v)
        this.applicantsForm.controls.postalCode.setValue(formattedValue, {emitEvent: false})
      }
    })
  }

  public formatPhone(control: AbstractControl) {
    control.setValue(formatPhone(control.value))
  }

  private formatPostalCode(value: string): string {
    // Remove all non-digit characters from the input string
    const numericValue = value.replace(/\D/g, '');

    // Limit the length to a maximum of 5 digits
    const limitedValue = numericValue.slice(0, 5);

    // Format the postal code as 'XXX XX'
    // $1 represents the first three digits, $2 the last two
    return limitedValue.replace(/^(\d{3})(\d{2})/, '$1 $2');
  }

}
