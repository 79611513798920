<form [formGroup]="primaryResidenceForm">
  <h3>Primärt boende</h3>
  <p>Med primärt boende i ett land räknas det land som du har din verkliga
    bosättning i, oftast där du är folkbokförd. Det ska vara fråga om en
    verklig och varaktig bosättning i landet, normalt sett en sammanhängande
    vistelseperiod om minst 6 månader.
  </p>

  <p>I vilket land har du ditt primära boende?</p>
  <div class="form-field-group"
       [class.small-screen]="orientationService.isSmallScreen$()">
    <mat-form-field>
      <mat-label>Välj land</mat-label>
      <mat-select formControlName="primaryResidence">
        @for (country of countryList; track $index) {
          <mat-option
            [ngClass]="{'separator-after': $index === 0}"
            [value]="country.name">{{ country.name }}
          </mat-option>
        }
      </mat-select>
      @if (primaryResidenceForm.controls.primaryResidence.touched) {
        <mat-error>Vänligen gör ett val</mat-error>
      }
    </mat-form-field>
  </div>
</form>
