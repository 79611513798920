import {Component, OnInit, ViewChild} from '@angular/core'
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms'
import {MatStepper} from '@angular/material/stepper'
import {ISparResponse} from 'sparbanken-spar'
import {phoneValidator} from '../../directives/PhoneValidator'
import {OrientationService} from '../../services/orientation.service'
import {SavingsAccountService} from '../../services/savings-account.service'

@Component({
  selector: 'spb-form',
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss'
})
export class FormComponent implements OnInit {
  @ViewChild('stepper') stepper!: MatStepper

  public welcomeForm: FormGroup = new FormGroup({
    terms: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [Validators.requiredTrue]
    })
  })

  public applicantsForm: FormGroup = new FormGroup({
    personalNumber: new FormControl<string | null>(null, {
      nonNullable: true
    }),
    name: new FormControl<string | null>(null, {
      nonNullable: true
    }),
    streetAdress: new FormControl<string | null>(null, {
      nonNullable: true
    }),
    streetNumber: new FormControl<string | null>(null, {
      nonNullable: true
    }),
    postalCode: new FormControl<string | null>(null, {
      nonNullable: true
    }),
    city: new FormControl<string | null>(null, {
      nonNullable: true
    }),
    countryCode: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    phone: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.required, phoneValidator]
    }),
    email: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [
        Validators.email,
        Validators.required]
    }),
    sparFailed: new FormControl<boolean>(false, {
      nonNullable: true
    })
  })

  public birthForm: FormGroup = new FormGroup({
    birthCountry: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    birthCity: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    citizenship: new FormArray([
      new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required]
      })
    ])
  })

  public primaryResidenceForm: FormGroup = new FormGroup({
    primaryResidence: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    })
  })

  public greenCardForm: FormGroup = new FormGroup({
    greenCard: new FormControl<boolean | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    })
  })

  public americanCitizenshipForm: FormGroup = new FormGroup({
    americanCitizenship: new FormControl<boolean | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    })
  })

  public taxResidencyForm: FormGroup = new FormGroup({
    taxResidencySwedish: new FormControl<boolean | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    taxResidencyNotSwedish: new FormControl<boolean | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    taxResidencyInfo: new FormArray([]),
    onlySwedenTaxCheckbox: new FormControl<boolean | null>(null, {
      nonNullable: true,
      validators: []
    }),
    notUsaCitizenCheckbox: new FormControl<boolean | null>(null, {
      nonNullable: true,
      validators: []
    })
  })

  public pepForm: FormGroup = new FormGroup({
    pepStatus: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    pepTitle: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    pepPosition: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    pepCountry: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    pepRelation: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    pepOtherRelation: new FormControl<boolean | null>(null, {
      nonNullable: true
    }),
    pepOtherRelationInfo: new FormControl<string | null>(null, {
      nonNullable: true
    }),
    pepOtherWork: new FormControl<boolean | null>(null, {
      nonNullable: true
    }),
    pepOtherWorkInfo: new FormControl<string | null>(null, {
      nonNullable: true
    })
  })

  public bankRelationshipForm: FormGroup = new FormGroup({
    openSavingsAccount: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [Validators.requiredTrue]
    }),
    openFixedRateAccount: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: []
    }),
    existingSavingsAccount: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: []
    }),
    existingSavingsAccountNumber: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: [Validators.pattern(/^\d{10,}$/) // At least 10 digits, allow leading zeros
      ]
    }),
    interestRunningTime: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: []
    }),
    interestPlacementAmount: new FormControl<string | null>(null, {
      nonNullable: true,
      validators: []
    }),
    wantToBeContacted: new FormControl<boolean | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    })
  })

  public incomeForm: FormGroup = new FormGroup({
    salary: new FormControl<number | null>(null, {nonNullable: true}),
    pension: new FormControl<number | null>(null, {nonNullable: true}),
    benefits: new FormControl<number | null>(null, {nonNullable: true}),
    studentAid: new FormControl<number | null>(null, {nonNullable: true}),
    soleProprietorship: new FormControl<number | null>(null, {nonNullable: true}),
    capital: new FormControl<number | null>(null, {nonNullable: true}),
    other: new FormControl<number | null>(null, {nonNullable: true})
  })

  public savingsForm: FormGroup = new FormGroup({
    savingsTotal: new FormControl<number | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    savingsSource: new FormArray([
      new FormControl(),
      new FormControl(),
      new FormControl(),
      new FormControl(),
      new FormControl()
    ])
  })

  public kycForm: FormGroup = new FormGroup({
    birthForm: this.birthForm,
    primaryResidenceForm: this.primaryResidenceForm,
    greenCardForm: this.greenCardForm,
    americanCitizenshipForm: this.americanCitizenshipForm,
    taxResidencyForm: this.taxResidencyForm,
    pepForm: this.pepForm,
    bankRelationshipForm: this.bankRelationshipForm,
    incomeForm: this.incomeForm,
    savingsForm: this.savingsForm
  })

  public savingsAccountForm: FormGroup = new FormGroup({
    id: new FormControl<string>('', {nonNullable: true}),
    welcomeForm: this.welcomeForm,
    applicantData: this.applicantsForm,
    kycForm: this.kycForm
  })

  constructor(
    protected savingsAccountService: SavingsAccountService,
    protected orientationService: OrientationService
  ) {
  }

  public ngOnInit() {
    const sub = this.savingsAccountService.applicant$().personalNumber

    this.savingsAccountService.getSpar(sub).subscribe({
      next: (r: ISparResponse) => {
        this.savingsAccountService.updateApplicantData(sub, r)
      },
      complete: () => {
        // Executes when the subscription is complete, regardless of success or failure.
        this.applicantsForm.patchValue(this.savingsAccountService.applicant$())

        // Track successful applicant logins upon receiving a response from SPAR.
        this.savingsAccountService.saveTrack().subscribe()
      },
      error: () => {
        this.savingsAccountService.updateApplicantData(sub, undefined)
        this.applicantsForm.controls.sparFailed.setValue(true)
        this.applicantsForm.controls.personalNumber.setValue(sub)
        this.applicantsForm.controls.countryCode.setValue('+46')
      }
    })

    // Set up a timer to reset the application after x minutes
    const stop = 30 * 60 * 1000
    setTimeout(() => {
      this.resetApplication()
    }, stop)
  }

  private resetApplication() {
    this.savingsAccountService.sessionTimeout$.set(true)
    this.savingsAccountForm.reset()
    this.savingsAccountService.applicant$.set({
      name: '', personalNumber: '', countryCode: '', email: '', phone: '',
      city: '', postalCode: '', streetNumber: '', streetAdress: ''
    })
  }

}
