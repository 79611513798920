<form [formGroup]="bankRelationshipForm">
  <h3>Välj sparkonto</h3>
  <div class="account-holder"
       [class.small-screen]="orientationService.isSmallScreen$()">

    <button class="account-option"
            (click)="toggleAccount('openSavingsAccount')"
            (keydown.enter)="toggleAccount('openSavingsAccount')"
            [class.selected]="bankRelationshipForm.get('openSavingsAccount')?.value">
      <div class="account-details">
        <h3>Sparkonto</h3>
        <p>{{ savingsInterest }}% ränta</p>
        <p class="underline">Ingen bindningstid</p>
      </div>
    </button>

    <button class="account-option"
            (click)="toggleAccount('openFixedRateAccount')"
            (keydown.enter)="toggleAccount('openFixedRateAccount')"
            [class.selected]="bankRelationshipForm.get('openFixedRateAccount')?.value">
      <div class="account-details">
        <h3>Fasträntekonto</h3>
        <p>{{ minInterestRate }}% - {{ maxInterestRate }}% ränta</p>
        <p class="underline">3 mån - 5 år bindningstid</p>
      </div>
    </button>

  </div>


  @if (bankRelationshipForm.controls.openFixedRateAccount.value === true) {
    <div
      class="interest-holder"
      [class.small-screen]="orientationService.isSmallScreen$()"
    >
      <p>Välj bindningstid och ränta ({{ interestDate }})</p>
      <mat-form-field>
        <mat-label>Vänligen välj här</mat-label>
        <mat-select
          formControlName="interestRunningTime"
        >
          <mat-option *ngFor="let option of interestRunningTimeOptions"
                      [value]="option.value">
            {{ option.label }} ({{ option.interestRate }}%)
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <p>Vänligen ange det belopp du vill sätta in på fasträntekontot</p>
    <mat-form-field
      class="margin-bottom-medium"
      [class.small-screen]="orientationService.isSmallScreen$()"
      subscriptSizing="dynamic"
    >
      <mat-label>Fastränteplacering belopp</mat-label>
      <input placeholder="Ange belopp"
             formControlName="interestPlacementAmount"
             inputmode="numeric"
             spbFormatNumber
             matInput type="text">

      @if (this.bankRelationshipForm.controls.interestPlacementAmount.invalid
      && !this.bankRelationshipForm.controls.interestPlacementAmount.untouched) {
        <mat-error
          class="error-width"
          [class.small-screen]="orientationService.isSmallScreen$()"
        >Minsta belopp att överföra är 50 000 kronor. Högsta belopp att överföra
          är 10 miljoner kronor.
        </mat-error>
      }
    </mat-form-field>
  }

  <div class="margin-bottom-medium">
    @if (bankRelationshipForm.controls.openFixedRateAccount.value === true) {
      <div [style.margin-bottom.px]="12">
        <mat-checkbox
          color="primary"
          class="column"
          formControlName="existingSavingsAccount">{{ iHaveSpbAccount }}
        </mat-checkbox>
      </div>

      @if (bankRelationshipForm.controls.existingSavingsAccount.value === true) {
        <mat-form-field
          class="margin-bottom-medium"
          [class.small-screen]="orientationService.isSmallScreen$()"
          subscriptSizing="dynamic"
        >
          <mat-label>Ange kontonummer (tio siffror)</mat-label>
          <input
            formControlName="existingSavingsAccountNumber"
            matInput type="text">
          @if (this.bankRelationshipForm.controls.existingSavingsAccountNumber.invalid
          && !this.bankRelationshipForm.controls.existingSavingsAccountNumber.untouched) {
            <mat-error
              class="error-width"
              [class.small-screen]="orientationService.isSmallScreen$()"
            >Vänligen ange ett konto i Sparbanken Syd. Minst 10 siffror.
            </mat-error>
          }
        </mat-form-field>
      }

      <mat-checkbox
        color="primary"
        class="column"
        formControlName="openSavingsAccount"
        (change)="bankRelationshipForm.patchValue({openSavingsAccount: $event.checked})">
        {{ iWantNewAccount }}
      </mat-checkbox>
    }

    @if (showDuplicateError) {
      <mat-error>Du kan endast välja ett alternativ. Vänligen välj antingen
        '{{ iHaveSpbAccount }}' eller '{{ iWantNewAccount }}'.
      </mat-error>
    }
  </div>
  <div class="margin-bottom-medium">

  </div>
  <div class="margin-bottom-medium">
    <p class="select-title">Utöver öppna ett sparkonto eller
      fastränteplacering vill jag även bli kontaktad för att bli informerad om
      bankens vardagstjänster och övriga produkter. </p>
    <div
      class="column">
      @for (option of yesOrNo; track $index) {
        <mat-checkbox
          color="primary"
          [checked]="option.value === bankRelationshipForm.controls.wantToBeContacted.value"
          (change)="onCheckboxChange(option.value,'wantToBeContacted', $event.checked)">
          {{ option.label }}
        </mat-checkbox>
      }
    </div>
  </div>
</form>
